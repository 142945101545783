import CancellationPolicyKeys from './cancellationPolicyKeys';

const CancellationPolicyDays = {
  [CancellationPolicyKeys.FREE]: 0,
  [CancellationPolicyKeys.FLEX]: 1,
  [CancellationPolicyKeys.SEMI_FLEXIBLE]: 2,
  [CancellationPolicyKeys.SEMI_MODERATE]: 5,
  [CancellationPolicyKeys.MODERATE]: 7,
  [CancellationPolicyKeys.FIRM]: 14,
  [CancellationPolicyKeys.STRICT]: 30,
  [CancellationPolicyKeys.STRICT_60]: 60,
  [CancellationPolicyKeys.STRICT_90]: 90,
};

export default CancellationPolicyDays;
