import t from '@guestyci/localize/t.macro';
import CancellationPolicyKeys from './cancellationPolicyKeys';

const CancellationPolicyText = () => ({
  [CancellationPolicyKeys.FREE]: t('2:00 PM at arrival date'),
  [CancellationPolicyKeys.FLEX]: t('1 day prior to arrival'),
  [CancellationPolicyKeys.SEMI_FLEXIBLE]: t('2 days prior to arrival'),
  [CancellationPolicyKeys.SEMI_MODERATE]: t('5 days prior to arrival'),
  [CancellationPolicyKeys.MODERATE]: t('7 days prior to arrival'),
  [CancellationPolicyKeys.FIRM]: t('14 days prior to arrival'),
  [CancellationPolicyKeys.STRICT]: t('30 days prior to arrival'),
  [CancellationPolicyKeys.STRICT_60]: t('60 days prior to arrival'),
  [CancellationPolicyKeys.STRICT_90]: t('90 days prior to arrival'),
  [CancellationPolicyKeys.NON_REFUNDABLE]: t('Non-refundable'),
});

export default CancellationPolicyText;
