'use client';

import { useMemo } from 'react';
import { shortLanguageList } from 'constants/languages';
import { useLocale as useIntlLocale } from 'next-intl';


const useLocale = () => {
  const locale = useIntlLocale();

  return useMemo(() => {
    return shortLanguageList.includes(locale) ? locale : 'en';
  }, [locale]);
};

export default useLocale;
