import { useState } from 'react';

import createStyles from '@guestyci/foundation/createStyles';
import Accordion from '@guestyci/foundation/Accordion';
import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField/TextField';
import Divider from '@guestyci/foundation/Divider/Divider';
import t from '@guestyci/localize/t.macro/t.macro';

import PriceConverter from 'components/PriceConverter';
import { formatTaxFeeTitle } from 'utils/index';
import { getTaxTypes, getFeeTypes } from 'constants/constants';
import OriginalPrice from 'components/OriginalPrice';

const useStyles = createStyles(() => ({
  root: {},
  oldSubTotal: {
    textDecoration: 'line-through',
  },
  totalWrapper: {
    cursor: 'pointer',
    position: 'relative',
  },
  accordionWrapper: {
    width: '100%',
    '& button[class*="Accordion-header"]': {
      width: 70,
    },
    '& div[class*="Accordion-title"]': {
      fontWeight: 'normal',
    },
    '& div[class*="Accordion-content"] > div': {
      padding: '5px 0px',
    },
  },
  totalPriceWrapper: {
    position: 'absolute',
    right: 0,
    top: 10,
  },
}));

const CollapseData = ({ totalAmount, globalCurrency, accordionData, title, roundPrice }) => {
  const [showAccordion, setShowAccordion] = useState(false);
  const { totalWrapper, accordionWrapper, totalPriceWrapper } = useStyles();

  return (
    <Row align="baseline" justify="between" className={totalWrapper} onClick={() => setShowAccordion((prev) => !prev)}>
      <Accordion
        className={accordionWrapper}
        title={<TextField color="secondary">{title}</TextField>}
        open={showAccordion}
      >
        {accordionData}
      </Accordion>
      <TextField className={totalPriceWrapper}>
        <PriceConverter roundPrice={roundPrice} amount={totalAmount} currency={globalCurrency} />
      </TextField>
    </Row>
  );
};

const CollapseDataRow = ({ title, amount, currency, roundPrice }) => (
  <Row data-qa="collapse-data-row" justify="between" fullWidth>
    <TextField color="secondary">{title}</TextField>
    <TextField color="secondary">
      <PriceConverter roundPrice={roundPrice} amount={amount} currency={currency} />
    </TextField>
  </Row>
);

export const Taxes = ({ invoiceItems, roundPrice }) => {
  const translatedTaxTypes = getTaxTypes();
  const globalCurrency = invoiceItems[0].currency;
  let totalAmount = 0;
  const taxes = invoiceItems
    .filter((tax) => tax.type === 'TAX')
    .map((tax) => {
      const { title, amount, currency, normalType, type } = tax;
      const translatedLabel = translatedTaxTypes[type]?.label;
      totalAmount += amount;
      const formattedTitle = formatTaxFeeTitle({ title, normalType, translatedLabel });
      return <CollapseDataRow roundPrice={roundPrice} title={formattedTitle} amount={amount} currency={currency} />;
    });

  if (totalAmount <= 0) {
    return null;
  }

  return (
    <CollapseData roundPrice={roundPrice} title={t('Taxes')} totalAmount={totalAmount} globalCurrency={globalCurrency} accordionData={taxes} />
  );
};

export const calculateFees = (invoiceItems) => {
  const translatedFeeTypes = getFeeTypes();
  let totalAmount = 0;
  const fees = invoiceItems
    .filter((tax) => tax.type === 'ADDITIONAL' || tax.type === 'CLEANING_FEE')
    .map((fee) => {
      const { title, amount, currency, normalType, type } = fee;
      const translatedLabel = translatedFeeTypes[type]?.label;
      totalAmount += amount;
      const formattedTitle = formatTaxFeeTitle({ title, normalType, translatedLabel });
      return {
        formattedTitle,
        amount,
        currency,
      };
    });

  return {
    totalAmount,
    fees,
  };
};

export const Fees = ({ invoiceItems, roundPrice = 0 }) => {
  const globalCurrency = invoiceItems[0].currency;
  // TODO: map over all fees
  const { totalAmount, fees } = calculateFees(invoiceItems);

  const feesItems = fees.map((fee) => (
    <CollapseDataRow roundPrice={roundPrice} title={fee.formattedTitle} amount={fee.amount} currency={fee.currency} />
  ));
  return (
    !!totalAmount && (
      <CollapseData
        roundPrice={roundPrice}
        title={t('Fees')}
        totalAmount={totalAmount}
        globalCurrency={globalCurrency}
        accordionData={feesItems}
      />
    )
  );
};

const Prices = ({ money = null, roundPrice = 0 }) => {
  if (!money) {
    return null;
  }

  const {
    currency,
    invoiceItems,
    fareAccommodation,
    fareAccommodationAdjusted,
    subTotalPrice,
    hostPayout,
    totalTaxes,
  } = money;

  const showOriginalPrice = fareAccommodation > fareAccommodationAdjusted;

  return (
    <Col>
      <Row className="pb-2" justify="between" align="end">
        <TextField bold>{t('Subtotal')}</TextField>
        <Col align="end">
          {showOriginalPrice && <OriginalPrice roundPrice={roundPrice} money={{ fareAccommodation, currency }} />}
          <TextField bold>
            <PriceConverter amount={fareAccommodationAdjusted} currency={currency} />
          </TextField>
        </Col>
      </Row>
      <Divider />
      <Fees roundPrice={roundPrice} invoiceItems={invoiceItems} />
      {totalTaxes > 0 && (
        <div data-qa="taxes">
          <Row className="pb-2 mt-2" justify="between" align="end">
            <TextField bold>{t('Subtotal before taxes')}</TextField>
            <Col align="center">
              <TextField bold>
                <PriceConverter amount={subTotalPrice} currency={currency} />
              </TextField>
            </Col>
          </Row>
          <Divider />
          <Taxes roundPrice={roundPrice} invoiceItems={invoiceItems} />
          <Divider />
        </div>
      )}
      <Row className="pb-2 pt-2" justify="between" align="end">
        <TextField bold variant="h3">
          {t('Total')}
        </TextField>
        <Col align="center">
          <TextField data-qa="total-price" bold variant="h3">
            <PriceConverter amount={hostPayout} currency={currency} />
          </TextField>
        </Col>
      </Row>
    </Col>
  );
};

export default Prices;
