'use client'

import { Row } from '@guestyci/foundation/Layout'
import createStyles from '@guestyci/foundation/createStyles';
import Icon from 'components/Icon';
import TextField from '@guestyci/foundation/TextField';

import { Link } from 'i18n/routing';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    flexWrap: 'wrap',
    '& > *': {
      [create('xs')]: {
        fontSize: 14,
      },
      [create('lg')]: {
        fontSize: 12,
      },
    },
    [create('xs')]: {
      padding: '10px 0 20px',
    },
    [create('lg')]: {
      padding: 0,
    },
  },
  home: {
    cursor: 'pointer',
  },
  arrow: {
    fill: '#BDBDBD',
  },
  contentWrapper: {
    gap: 10,
  },
}));


const NextBreadcrumb = ({ breadcrumbs }) => {
  const { arrow, root, contentWrapper } = useStyles();

  return (
    <Row align="center" spacing="2" className={root}>
      {breadcrumbs.map(({ label, url }, index) => (
        <Row className={contentWrapper} align="center" key={url}>
          {!!index && <Icon icon="arrow_right" size={23} className={arrow} />}
          <Link key={url} href={url}>
            <TextField color="secondary">
              <h2 className="inherit-all">{label}</h2>
            </TextField>
          </Link>
        </Row>
      ))}
    </Row>
  )
}

export default NextBreadcrumb
