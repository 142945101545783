import cn from 'classnames';

import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';

import PriceConverter from 'components/PriceConverter';

const useStyles = createStyles(() => ({
  originalPrice: {
    textDecoration: 'line-through',
  },
}));

const OriginalPrice = ({ money = {}, className, roundPrice }) => {
  const { originalPrice } = useStyles();
  const { fareAccommodation, currency } = money;

  return (
    <TextField data-qa="subtotal-price" className={cn(className, originalPrice)} color="light">
      <PriceConverter roundPrice={roundPrice} amount={fareAccommodation} currency={currency} />
    </TextField>
  );
};

export default OriginalPrice;
