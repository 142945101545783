/* eslint-disable no-restricted-syntax */
import _ from 'lodash';

const FARE_ACCOMMODATION_ADJUSTED = 'fareAccommodationAdjusted';
const FARE_ACCOMMODATION = 'fareAccommodation';
const HOST_PAYOUT = 'hostPayout';
const TOTAL_FEES = 'totalFees';
const TOTAL_TAXES = 'totalTaxes';
const SUBTOTAL_PRICE = 'subTotalPrice';

export function combineInvoiceItems(invoiceItems) {
  return _(invoiceItems)
    .flatten()
    .filter((item) => item.type !== 'MANUAL')
    .groupBy('title')
    .map((items, title) => {
      return {
        ...items[0],
        title,
        amount: _.sumBy(items, 'amount'),
      };
    })
    .value();
}

export function getSelectedRatePlans(quote, selectedRatePlanId) {
  return quote.map((q) => {
    const {
      rates: { ratePlans },
    } = q;
    return _.find(ratePlans, (rp) => rp.ratePlan._id === selectedRatePlanId);
  });
}

export function calculateMoney(selectedRatePlans, key) {
  return selectedRatePlans?.reduce((acc, { ratePlan }) => {
    const { money } = ratePlan;
    return acc + money[key];
  }, 0);
}

export function calcualteInvoiceItems(invoices) {
  if (!invoices) return [];
  // Create a map to store combined invoice items
  const combinedItemsMap = new Map();

  // Iterate through each invoice
  for (const invoice of invoices) {
    // Access invoice items from the nested money object
    const { invoiceItems } = invoice.ratePlan.money;

    // Iterate through each item in the invoice
    for (const item of invoiceItems) {
      const key = item.title; // Use type and currency as key

      if (combinedItemsMap.has(key)) {
        // If item exists, update the amount
        const existingItem = combinedItemsMap.get(key);
        existingItem.amount += item.amount;
      } else {
        // Otherwise, add the item to the map
        combinedItemsMap.set(key, { ...item }); // Make a copy of the item
      }
    }
  }

  // Convert map values back to an array
  return Array.from(combinedItemsMap.values());
}

export function calculateFareAccommodationAdjusted(selectedRatePlans) {
  return calculateMoney(selectedRatePlans, FARE_ACCOMMODATION_ADJUSTED);
}

export function calculateFareAccommodation(selectedRatePlans) {
  return calculateMoney(selectedRatePlans, FARE_ACCOMMODATION);
}

export function calculateHostPayout(selectedRatePlans) {
  return calculateMoney(selectedRatePlans, HOST_PAYOUT);
}

export function calculateTotalFees(selectedRatePlans) {
  return calculateMoney(selectedRatePlans, TOTAL_FEES);
}

export function calculateTotalTaxes(selectedRatePlans) {
  return calculateMoney(selectedRatePlans, TOTAL_TAXES);
}

export function calculateSubTotalPrice(selectedRatePlans) {
  return calculateMoney(selectedRatePlans, SUBTOTAL_PRICE);
}
